<template>
  <div class="home">
    <swiper class="outSwiperBox"
            :autoplay="swiper_options.autoplay"
            :loop="swiper_options.loop"
            :speed="swiper_options.speed"
            :pagination="swiper_options.pagination"
            :navigation="swiper_options.navigation"
            :spaceBetween="swiper_options.spaceBetween"
            :coverflowEffect="swiper_options.coverflowEffect"
            :centeredSlides="swiper_options.centeredSlides"
            :slidesPerView="swiper_options.slidesPerView"
            @swiper="onSwiper"
            @slideChange="onSlideChange">
      <swiper-slide><img src="../assets/banner.png"
             alt=""></swiper-slide>
      <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/24/b2fd0fbf09f883bc.png"
             alt=""></swiper-slide>
      <swiper-slide><img src="https://s3.bmp.ovh/imgs/2022/05/24/983da74e8f4ee1f0.png"
             alt=""></swiper-slide>
      <div class="btn-left"></div>
      <div class="btn-right"></div>
    </swiper>
    <div class="retailManagement">
      <div class="container">
        <h2 class="wow slideInDown">零售管理系统</h2>
        <div class="img wow slideInLeft">
          <img src="../assets/retailManagement.png"
               alt="">
        </div>
        <div class="list">
          <div class="item member wow slideInLeft">
            <div class="number">01</div>
            <div class="introduction">
              <h3>成长会员</h3>
              <p>按车主消费量定制会员等级，锁定客户在站点的忠诚度。</p>
            </div>
          </div>
          <div class="item point wow slideInLeft">
            <div class="number">02</div>
            <div class="introduction">
              <h3>积分系统</h3>
              <p>可依照不同等级、消费，充值予以不同的积分，增加站点与车主的粘性。</p>
            </div>
          </div>
          <div class="item recall wow slideInRight">
            <div class="number">03</div>
            <div class="introduction">
              <h3>召回系统</h3>
              <p>通过多数据的计算流失客户群，一键召回或电话回访车主，减少站点损失。</p>
            </div>
          </div>
          <div class="item autonomy-fuel wow slideInRight">
            <div class="number">04</div>
            <div class="introduction">
              <h3>一键加油</h3>
              <p>车主更加便捷，员工更加高效，增加站点高峰期时代的加油频次</p>
            </div>
          </div>
          <div class="item marketing wow slideInRight">
            <div class="number">05</div>
            <div class="introduction">
              <h3>营销活动</h3>
              <p>可设置时间段、会员等级、优惠模式，针对不同的站点活动设置不同的影响活动</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="framework wow animate__fadeIn"
         data-wow-duration="5s">
      <div class="container">
        <h2>架构图</h2>
      </div>
    </div>
    <div class="analysis">
      <div class="container">
        <div class="analysis-describe">
          <h2 class="wow slideInDown">数据分析系统</h2>
          <div>
            <img class="wow slideInLeft"
                 src="../assets/Icon-command1.png"
                 alt="">
            <span class="wow slideInRight">针对站点角色，设置不同的数据分析</span>
          </div>
          <div>
            <img class="wow slideInLeft"
                 src="../assets/Icon-command1.png"
                 alt="">
            <span class="wow slideInRight">多登录平台，随时随地查询站点数据</span>
          </div>
          <div>
            <img class="wow slideInLeft"
                 src="../assets/Icon-command3.png"
                 alt="">
            <span class="wow slideInRight">定制报表，更符合站点自身的报表体系</span>
          </div>
        </div>
      </div>
    </div>
    <div class="fuel">
      <img class="fuel-img wow animate__backInUp"
           src="../assets/fuel.png"
           alt="">
      <div class="container">
        <div class="fuel-describe">
          <h2 class="wow slideInDown">非油管理系统</h2>
          <p class="wow slideInRight">为加油站打造拥有符合自身非油品管理系统</p>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="container">
        <div class="left wow animate__zoomIn">
          <div class="img">
            <img src="../assets/maskGroup.png"
                 alt="">
          </div>
          <dir class="box"></dir>
        </div>
        <div class="right wow animate__slideInRight">
          <h2>乐油盒子</h2>
          <p>由无线网与有线网进行数据传输，不再为脱机断网而烦恼</p>
          <p>乐油传输数据直接上传至云端，减少了传输媒介，实现本地无数据化，保障数据的绝对安全</p>
          <p>乐油盒子的存在，保障了加油站在电脑主机在关机的状态，能够正常运营</p>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="service-content container  wow animate__fadeIn"
           data-wow-duration="2s">
        <h2>我们的服务</h2>
        <div class="item major">
          <div class="img">
            <img src="../assets/service-icon1.png"
                 alt="">
          </div>
          <h3>专业</h3>
          <p>系统由资深工程师 安装调试</p>
        </div>
        <div class="item continued">
          <div class="img">
            <img src="../assets/service-icon3.png"
                 alt="">
          </div>
          <h3>持续</h3>
          <p>运营团队定期进行，油站 营销活动相关支撑</p>
        </div>
        <div class="item quality">
          <div class="img">
            <img src="../assets/service-icon5.png"
                 alt="">
          </div>
          <h3>质量</h3>
          <p>配备油站专属客户经理， 确保售后服务品质</p>
        </div>
        <div class="item flexible">
          <div class="img">
            <img src="../assets/service-icon6.png"
                 alt="">
          </div>
          <h3>灵活</h3>
          <p>培训团队可远程，指导 操作要领</p>
        </div>
        <div class="item-reverse item comprehensive">
          <h3>全面</h3>
          <p>详尽系统使用教程供 油站随时参考</p>
          <div class="img">
            <img src="../assets/service-icon2.png"
                 alt="">
          </div>
        </div>
        <div class="item-reverse item timely">
          <h3>全面</h3>
          <p>详尽系统使用教程供 油站随时参考</p>
          <div class="img">
            <img src="../assets/service-icon4.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper core and required modules
// import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import SwiperCore, { Autoplay, Pagination, EffectCoverflow, Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import WOW from "wow.js";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import { onMounted, reactive } from '@vue/runtime-core';
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
/**
 * 如果嫌麻烦可以把上面的全部样式都删了，直接用总的
 * bundle：所有 Swiper 样式，包括所有模块样式（如导航、分页等）
 */
// import "swiper/swiper-bundle.min.css";

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup () {
    let swiper_options = reactive({
      autoplay: {
        disableOnInteraction: false, // 鼠标滑动后继续自动播放
        delay: 8000, //4秒切换一次
      },
      speed: 500, //切换过渡速度
      loop: true,
      slidesPerView: "auto", //解决最后一张切换到第一张中间的空白
      centeredSlides: true, //设置slide居中
      spaceBetween: 20,
      coverflowEffect: {
        // rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        stretch: 50, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
        depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        modifier: 1, //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
        // slideShadows: false, //开启slide阴影。默认 true。
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      pagination: {
        clickable: true,
      },
    })
    onMounted(() => {
    })
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      swiper_options
      // modules: [Autoplay, Navigation, Pagination, A11y],
    };
  },
};
</script>
<style lang="scss" scoped>
.outSwiperBox {
  height: 750px;
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 20px !important;

    &:after {
      background: #ffffff;
    }
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0;
    &:after {
      background-color: deeppink;
    }
  }
}
.swiper-slide {
  img {
    width: 100%;
    height: 100%;
  }
}
.retailManagement {
  height: 947px;
  .container {
    h2 {
      font-size: 48px;
      font-weight: bold;
      color: #000000;
      line-height: 56px;
      margin-bottom: 77px;
      padding-top: 64px;
    }
    .img {
      width: 440px;
      height: 356px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item {
      display: flex;
      .number {
        width: 39px;
        height: 37px;
        background: #608af5;
        box-shadow: 0px 0px 17px 1px rgba(96, 138, 245, 0.25);
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
        border-radius: 50%;
      }
      .introduction {
        margin-left: 31px;
        h3 {
          font-size: 24px;
          font-weight: bold;
          color: #1b2430;
          line-height: 32px;
        }
        p {
          width: 353px;
          font-size: 18px;
          font-weight: bold;
          color: #3d3d3d;
          line-height: 28px;
        }
      }
    }
    .member {
      position: absolute;
      top: 598px;
      left: 0;
    }
    .point {
      position: absolute;
      top: 779px;
      left: 0;
    }
    .autonomy-fuel {
      position: absolute;
      right: 0;
      top: 557px;
    }
    .marketing {
      right: 0;
      top: 743px;
      position: absolute;
    }
    .recall {
      position: absolute;
      right: 0;
      top: 375px;
    }
  }
}
.framework {
  background: url("../assets/framework.png") no-repeat;
  background-position: center center;
  background-size: 1260px 669px;
  margin-top: 20px;
  height: 669px;
  h2 {
    font-size: 48px;
    font-weight: bold;
    color: #000000;
    line-height: 56px;
  }
}
.analysis {
  background: url("../assets/analysis.png") no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  height: 906px;
  width: 100%;
  .analysis-describe {
    padding-top: 164px;
    h2 {
      font-size: 48px;
      font-weight: bold;
      color: #ffffff;
      line-height: 56px;
    }
    div {
      display: flex;
      align-items: center;
      margin-top: 114px;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
        margin-left: 24px;
      }
    }
  }
}
.fuel {
  position: relative;
  background: url("../assets/BG.png") no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  height: 853px;
  width: 100%;
  .fuel-img {
    position: absolute;
    left: 454px;
    top: 306px;
    height: 495px;
  }
  .fuel-describe {
    padding-top: 64px;
    h2 {
      font-size: 48px;
      font-weight: bold;
      color: #000000;
      line-height: 56px;
    }
    p {
      font-size: 24px;
      font-weight: bold;
      color: #1b2430;
      line-height: 28px;
      margin-top: 37px;
    }
  }
}
.product {
  height: 911px;
  background: url("../assets/product.png") no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding-top: 181px;
  .container {
    display: flex;
    align-items: center;
    .left {
      position: relative;
      .img {
        width: 625px;
        height: 564px;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box {
        width: 625px;
        height: 564px;
        background-color: #608af5;
        margin-top: -35px;
        margin-left: 50px;
      }
    }
    .right {
      margin-left: 63px;
      h2 {
        font-size: 48px;
        font-weight: bold;
        color: #ffffff;
        line-height: 56px;
        margin-bottom: 30px;
      }
      p {
        width: 532px;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
        margin-bottom: 10px;
      }
    }
  }
}
.service {
  height: 916px;
  background: url("https://s3.bmp.ovh/imgs/2022/05/24/8a13e81cc0eb1c7a.png")
    no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding-top: 67px;
  .service-content {
    position: relative;
    width: 1283px;
    height: 666px;
    background: rgba(255, 255, 255, 0.95);
    padding: 127px 0px 0px 30px;
    h2 {
      font-size: 48px;
      font-weight: bold;
      color: #000000;
      line-height: 56px;
    }
    .item {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      .img {
        width: 98px;
        height: 98px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h3 {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 21px;
        text-align: center;
        margin: 22px 0px 7px 0px;
      }
      p {
        width: 154px;
        font-size: 14px;
        color: #777777;
        line-height: 16px;
      }
    }
    .item-reverse {
      p {
        margin: 7px 0px 29px 0px;
      }
    }
    .major {
      top: 379px;
      left: 143px;
    }
    .continued {
      top: 475px;
      left: 378px;
    }
    .quality {
      top: 411px;
      left: 654px;
    }
    .flexible {
      top: 338px;
      left: 956px;
    }
    .comprehensive {
      top: 162px;
      left: 471px;
    }
    .timely {
      top: 127px;
      left: 759px;
    }
  }
}
</style>
