import { createRouter, createWebHistory } from "vue-router";
import index from "../page/index.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/index",
      name: "index",
      // meta: {
      //   keepAlive: true
      // },
      component: index,
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "../page/about.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "about" */ "../page/contact.vue"),
    },
  ],
});
router.afterEach((to, from, next) => {
  window, scrollTo(0, 0);
});
export default router;
