<template>
  <header class="index"
          :class="state.navBarFixed?'fix-nav':''">
    <div class="container">
      <div class="left">
        <img src="../assets/logo.png"
             alt="">
        <span>乐得科技</span>
      </div>
      <ul>
        <li @click="index">首页</li>
        <li class="as"
            @mouseenter="enter"
            @mouseleave="leave">智慧油站
          <div class="tip"
               @mouseover="enter">
            <div class="item"
                 @click="go1">智慧管理系统</div>
            <div class="item"
                 @click="go2">数据分析系统</div>
            <div class="item"
                 @click="go3">非油管理系统</div>
            <div class="item"
                 @click="go4">乐油盒子</div>
          </div>
        </li>
        <li @click="contact">联系我们</li>
        <li @click="about">关于我们</li>
      </ul>
    </div>
  </header>
  <div class="suspension">
    <div class="item cursor">
      <img class="icon"
           src="../assets/phone.png"
           alt="">
      <p>咨询电话</p>
      <div class="content">
        <span class="arrow_right"></span>
        联系电话:028-63041025
        <br>
        联系电话:17780444886
      </div>
    </div>
    <div class="item cursor">
      <img class="icon"
           src="../assets/wx.png"
           alt="">
      <p>微信</p>
      <div class="content">
        <span class="arrow_right"></span>
        <img src="../assets/scan.jpg"
             alt="">
      </div>
    </div>
    <div class="item cursor"
         v-if="!state.value"
         @click="toTop">
      <img class="icon"
           src="../assets/top.png"
           alt="">
      <p>返回顶部</p>
    </div>

  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { onMounted, onUnmounted, reactive } from 'vue'
export default {

  setup () {
    let state = reactive({
      navBarFixed: false,
      value: false
    })
    onMounted(() => {
      window.addEventListener("scroll", watchScroll);
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", watchScroll);
    })
    let router = useRouter()
    let about = () => {
      router.push('/about')
    }
    let contact = () => {
      router.push('/contact')
    }
    let index = () => {
      router.push('/index')
    }
    let enter = () => {
      console.log('进入');
      document.getElementsByClassName('tip')[0].classList.add('as')
    }
    let leave = () => {
      console.log('leave');
      setTimeout(() => {
        document.getElementsByClassName('tip')[0].classList.remove('as')
      }, 2000);
    }
    let enter1 = () => {
      document.getElementsByClassName('tip')[0].classList.add('as')
    }
    let go1 = () => {
      if (router.currentRoute._value.path != '/index') {
        router.push('/index')
        setTimeout(() => {
          document.getElementsByClassName('analysis')[0].scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
          })
        }, 100);
        return
      }
      document.getElementsByClassName('retailManagement')[0].scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block: "start"  // 上边框与视窗顶部平齐。默认值
      })
    }
    let go2 = () => {
      if (router.currentRoute._value.path != '/index') {
        router.push('/index')
        setTimeout(() => {
          document.getElementsByClassName('analysis')[0].scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
          })
        }, 100);
        return
      }
      document.getElementsByClassName('analysis')[0].scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block: "start"  // 上边框与视窗顶部平齐。默认值
      })
    }
    let go3 = () => {
      if (router.currentRoute._value.path != '/index') {
        router.push('/index')
        setTimeout(() => {
          document.getElementsByClassName('analysis')[0].scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
          })
        }, 100);
        return
      }
      document.getElementsByClassName('fuel')[0].scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block: "start"  // 上边框与视窗顶部平齐。默认值
      })
    }
    let go4 = () => {
      if (router.currentRoute._value.path != '/index') {
        router.push('/index')
        setTimeout(() => {
          document.getElementsByClassName('product')[0].scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block: "start"  // 上边框与视窗顶部平齐。默认值
          })
        }, 100);
        return
      }
      document.getElementsByClassName('product')[0].scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block: "start"  // 上边框与视窗顶部平齐。默认值
      })
    }
    let watchScroll = () => {
      // 滚动的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 容器的高度
      var offsetTop = document.getElementsByClassName('index')[0].offsetHeight;
      // console.log("scrollTop=>", scrollTop, "  offsetTop=>", offsetTop);
      // //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if (scrollTop > offsetTop) {
        state.navBarFixed = true;
      } else {
        state.navBarFixed = false;
      }
      //往下滑超过20则显示 否则则不显示按钮
      if (scrollTop > 200) {
        state.value = false
      } else {
        state.value = true
      }
    }
    let toTop = () => {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
    return {
      about,
      contact,
      index,
      enter,
      leave,
      enter1,
      go1,
      go2,
      go3,
      go4,
      watchScroll,
      toTop,
      state
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  li:nth-child(2) {
    position: relative;
    // overflow: hidden;
    .tip {
      position: absolute;
      z-index: 99;
      top: 39px;
      background: #ffffff;
      box-shadow: 0px 10px 24px 1px rgba(0, 0, 0, 0.19);
      border-radius: 4px 4px 4px 4px;
      width: 212px;
      height: 0px;
      transition: 1s;
      overflow: hidden;
      .item {
        width: 100%;
        padding: 15px 0px 14px 0px;
        color: black;
        font-size: 16px;
        color: #000000;
        line-height: 27px;
        text-align: center;
        &:hover {
          background: #acbaf2;
          color: white;
        }
      }
    }
    .as {
      height: 224px;
      transition: 0.5s;
    }
  }
}
.suspension {
  width: 80px;
  position: fixed;
  right: 0px;
  top: 600px;
  z-index: 999;

  .item {
    border-radius: 4px;
    margin-bottom: 1px;
    background-color: #3188da96;
    opacity: 0.9;
    padding: 18px 5px;
    text-align: center;
    // line-height: 80px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    .content {
      width: 220px;
      min-height: 40px;
      background: #3188da96;
      padding: 10px 10px;
      margin: 20px;
      position: absolute;
      right: 75px;
      border-radius: 4px;
      top: 0px;
      display: none;
      .arrow_right {
        display: block;
        overflow: hidden;
        width: 0px;
        height: 0px;
        padding: 0;
        font-size: 0;
        border: 8px solid transparent;
        border-left-style: solid;
        border-left-color: #3188da96;
        position: absolute;
        top: 15px;
        right: -16px;
      }
    }
    .icon {
      width: 30px;
      height: 30px;
    }
    p {
      font-size: 14px;
    }
    &:hover .content {
      display: block;
    }
  }
}
</style>