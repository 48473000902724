<template>
  <footer>
    <div class="container">
      <h2>
        成都乐得科技有限公司
      </h2>
      <div class="introduction">
        <p>成都乐得科技有限公司
          一家集信息技术、软硬件开发、智慧油站服务为一体的高新技术企业。解决方案旨在为加油站、撬装站、加气站提供一站式“物联网+”系统解决方案，致力于成为行业物联网方案提供商。</p>
        <div class="scan">
          <div class="scan-code">
            <img src="../assets/scan.jpg"
                 alt="">
          </div>
          <p>扫一扫关注微信~</p>
        </div>
      </div>
      <div class="contact">
        <div class="phone as">
          <h3>028-63041025 &nbsp; 17780444886</h3>
          <p>办公电话</p>
        </div>
        <div class="location as">
          <h3>成都市高新区天府四街时代晶座3栋308</h3>
          <p>办公地址</p>
        </div>
      </div>
    </div>
  </footer>
  <div class="footer-bottom">
    <a href="https://beian.miit.gov.cn">蜀ICP备20022201号-1</a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>